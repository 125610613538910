import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[stopPropag]',
})
export class StopPropagDirective {
  constructor(private el: ElementRef) {
    this.el.nativeElement.addEventListener('click', (e: Event) => {
      e.stopPropagation();
    });
  }
}
