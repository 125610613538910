import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Observable, Observer, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IHistoryCurvesCard } from 'src/app/models/historic';
import { HttpParams } from '@angular/common/http';

export interface Message {
  waves: Record<string, number[]>;
}
@Injectable({
  providedIn: 'root',
})
export class CurvesService {
  constructor(private http: HttpService) {}

  private observable = {} as { [key: number]: Observable<Message> };

  private secondsRange = [2, 10] as const;
  private widthRange = [320, 1440] as const;

  ModeCurvesSubject = new Subject<string>();

  watchModeCurves() {
    return this.ModeCurvesSubject.asObservable();
  }

  changeModeCurves(socket: string) {
    this.ModeCurvesSubject.next(socket);
  }

  unwatchModeCurves() {
    this.ModeCurvesSubject.unsubscribe();
  }

  timeViewMs(layout?: HTMLDivElement) {
    let width = window.innerWidth;
    const [min, max] = this.widthRange;
    let [minSec, maxSec] = this.secondsRange;
    if (layout) {
      const rect = layout.getBoundingClientRect();
      width = rect.width;
      minSec += 4;
      maxSec += 4;
    }
    const seconds = minSec + ((maxSec - minSec) * (width - min)) / (max - min);
    return seconds * 1000;
  }

  public connect(id: number): Observable<Message> {
    if (!this.observable[id]) {
      this.observable[id] = this.create(id);
    }
    return this.observable[id];
  }

  public disconnect(id: number): void {
    if (this.observable[id]) {
      console.log('WebSocket disconnected from wave-bed ' + id);
      delete this.observable[id];
    }
  }

  private create(id: number): Observable<Message> {
    const ws = new WebSocket(environment.socket.wave_bed + id);
    ws.onopen = () => {
      console.log('WebSocket connected to wave-bed ' + id);
    };
    const observable = new Observable((obs: Observer<Message>) => {
      ws.onmessage = (msg) => {
        const data = JSON.parse(msg.data);
        obs.next(data);
      };
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    return observable;
  }

  getHistory(body: IHistoryCurvesCard) {
    const params = new HttpParams()
      .set('equipment', body.equipment)
      .set('date', body.date);
    return this.http.get<Message>('core/get-wave/', params);
  }
}
