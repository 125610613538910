<nav [ngClass]="{hidden}">
  <div class="content">
    <button class="toggle"
        mat-icon-button
        (click)="drawerToggle()"
      >
        <i icon="menu"></i>
      </button>

    <a class="logo" routerLink="/">
      <img [src]="visual_project.navbar" alt="logo" />
    </a>

    <div class="font-control">
      <button
        mat-icon-button
        (click)="changeFontSize('reset')"
        [disabled]="fontSize === 1"
      >
        <i icon="return"></i>
      </button>
      <button
        mat-icon-button
        (click)="changeFontSize('decrease')"
        [disabled]="fontSize <= -2"
      >
        A-
      </button>
      <button
        mat-icon-button
        (click)="changeFontSize('increase')"
        [disabled]="fontSize >= 8"
      >
        A+
      </button>
    </div>

    <div class="left">
      <p class="name f-15" *ngIf="config?.system_settings?.hospital">
        {{ config.system_settings.hospital }}
      </p>
      <p class="clock">{{ now | async | fDate : "datetime" }}</p>

      <div class="avatar">
        <avatar
          [name]="user.name"
          color="dark"
          bg="#fff"
          *ngIf="user.name"
        ></avatar>
      </div>
    </div>
  </div>
</nav>

<mat-drawer-container>
  <mat-drawer #drawer [opened]="!hidden" mode="side">
    <div class="nav-list">
      <a
        *ngIf="checkAccess('monitoring')"
        aria-label=""
        mat-icon-button
        matTooltip="Monitores"
        matTooltipPosition="after"
        routerLink="/monitoring"
        routerLinkActive="active"
      >
        <i icon="home"></i>
      </a>

      <a
        *ngIf="checkAccess('beds')"
        aria-label=""
        mat-icon-button
        [matTooltip]="'bed.title' | translate"
        matTooltipPosition="after"
        routerLink="/beds"
        routerLinkActive="active"
      >
        <i icon="beds"></i>
      </a>

      <a
        *ngIf="checkAccess('patients')"
        aria-label=""
        mat-icon-button
        matTooltip="Pacientes"
        matTooltipPosition="after"
        routerLink="/patients"
        routerLinkActive="active"
      >
        <i icon="patients"></i>
      </a>

      <a
        *ngIf="checkAccess('equipment')"
        aria-label=""
        mat-icon-button
        matTooltip="Equipamentos"
        matTooltipPosition="after"
        routerLink="/equipment"
        routerLinkActive="active"
      >
        <i icon="equipment"></i>
      </a>

      <a
        *ngIf="checkAccess('alarms')"
        aria-label=""
        mat-icon-button
        [matTooltip]="'alarm.alarm_config.title' | translate"
        matTooltipPosition="after"
        routerLink="/alarm-config"
        routerLinkActive="active"
      >
        <i icon="alarms"></i>
      </a>

      <a
        aria-label=""
        mat-icon-button
        [matTooltip]="'alarm.active_alarms.title' | translate"
        matTooltipPosition="after"
        routerLink="/active-alarms"
        routerLinkActive="active"
      >
        <i icon="alarm_test"></i>
      </a>

      <a
        *ngIf="checkAccess('historic')"
        aria-label=""
        mat-icon-button
        [matTooltip]="'historic.title' | translate"
        matTooltipPosition="after"
        routerLink="/historic"
        routerLinkActive="active"
      >
        <i icon="history"></i>
      </a>

      <a
        *ngIf="checkAccess('users')"
        aria-label=""
        mat-icon-button
        matTooltip="Usuários"
        matTooltipPosition="after"
        routerLink="/users"
        routerLinkActive="active"
      >
        <i icon="users"></i>
      </a>

      <a
        *ngIf="checkAccess('dictionary-params')"
        aria-label=""
        mat-icon-button
        [matTooltip]="'dictionary.title' | translate"
        matTooltipPosition="after"
        routerLink="/dictionary-params"
        routerLinkActive="active"
      >
        <i icon="dictionary"></i>
      </a>

      <a
        *ngIf="checkAccess('system-settings')"
        aria-label=""
        mat-icon-button
        matTooltip="Configurações de Sistema"
        matTooltipPosition="after"
        routerLink="/system-settings"
        routerLinkActive="active"
      >
        <i icon="system_settings"></i>
      </a>

      <a
        aria-label=""
        class="exit-icon"
        mat-icon-button
        [matTooltip]="'config_channel.exit' | translate"
        matTooltipPosition="after"
        (click)="logout()"
      >
        <i icon="exit"></i>
      </a>
    </div>
  </mat-drawer>

  <mat-drawer-content [ngClass]="{hidden}">
    <router-outlet *ngIf="!loading"></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>
<fps-meter></fps-meter>
