import { Injectable } from '@angular/core';
import { LightningChart, lightningChart } from '@lightningchart/lcjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LcContextService {
  private lc?: LightningChart;

  public getLightningChartContext() {
    if (this.lc) return this.lc;
    this.lc = lightningChart(environment.lcjs);
    return this.lc;
  }
}
