export const environment = {
  production: false,
  base_url: 'https://apidev.skopien.com.br/',
  page_size: 10,
  socket: {
    wave_bed: 'wss://socketdev.skopien.com.br/wave-bed/',
    monitor_param: 'wss://socketdev.skopien.com.br/monitor_param/',
    default: 'wss://socketdev.skopien.com.br/default/',
  },
  lcjs: {
    license:
      '0002-aACCYKDAFf9Jyw6z6ARUSe7YT3oQBwABgmK02RD1-MEQCIFoUVd6ZQzsCRFWXFc1mVJDfUNB6oNObup0A68TlaOVYAiBTSJBTh1zPzG5EPdXsf1Ns4adKYjX/vGpYD5yn14E1uQ==',
    licenseInformation: {
      appTitle: 'LightningChart JS Trial', // 05/07/2024
      company: 'LightningChart Ltd.',
    },
  },
};
