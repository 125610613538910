import {HttpService} from "./../http/http.service";
import {HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {IPagedReq} from "src/app/models/utils";
import {
  IDefaultSettings,
  ISystemSettings,
} from "src/app/models/system-settings";

@Injectable({
  providedIn: "root",
})
export class SystemSettingsService {
  constructor(private http: HttpService) {
  }

  private _system_settings = new BehaviorSubject<ISystemSettings>(
    {} as ISystemSettings
  );

  readonly system_settings = this._system_settings.asObservable();

  public selectedPatientAge: number | undefined;

  getSettings() {
    return this.http.get<ISystemSettings>("core/get-config/");
  }

  getSettingsOptions() {
    return this.http.get<IDefaultSettings>("core/get-default-config");
  }

  updateSettings(settings: ISystemSettings) {
    const body = {
      id: settings.id,
      user: settings.user,
      volume: settings.volume,
      monitor_exibition: settings.monitor_exibition,
      data_format: settings.data_format,
      hour_format: settings.hour_format,
      language: settings.language,
      system_settings: {
        data_storage: settings.system_settings.data_storage,
        hospital: settings.system_settings.hospital,
      },
    };
    return this.http.patch<ISystemSettings>("core/update-config/", body);
  }

  set fontSize(size: number) {
    localStorage.setItem("font_size", size + '');
  }

  get fontSize() {
    return Number(localStorage.getItem("font_size")) || 1;
  }
}
