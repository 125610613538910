export const CONFIG_CURVES = {
  approxDataIntervalFromServerMs: 5_000,
  waves: {
    default: {
      name: '',
      model: 'default',
      color: '#62ff',
      rate: 500,
      secs: 9,
      variable: 'x',
    },
    ecg_i: {
      name: 'I',
      model: 'ecg_i',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    ecg_ii: {
      name: 'II',
      model: 'ecg_ii',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    ecg_iii: {
      name: 'III',
      model: 'ecg_iii',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    ecg_v: {
      name: 'V',
      model: 'ecg_v',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    ecg_avf: {
      name: 'AVF',
      model: 'ecg_avf',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    ecg_avl: {
      name: 'AVL',
      model: 'ecg_avl',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    ecg_avr: {
      name: 'AVR',
      model: 'ecg_avr',
      color: '#62fe34',
      rate: 256,
      secs: 8,
    },
    pi_i: {
      name: 'PI I',
      model: 'pi_i',
      color: '#AA0000',
      rate: 256,
      secs: 8,
    },
    plenth: {
      name: 'plenth',
      model: 'plenth',
      color: '#00deff',
      rate: 256,
      secs: 15,
    },
    resp: {
      name: 'RESP',
      model: 'resp',
      color: '#FFCC00',
      rate: 128,
      secs: 20,
    },
    NOM_ECG_ELEC_POTL_I: {
      name: 'I',
      model: 'NOM_ECG_ELEC_POTL_I',
      color: '#62fe34',
      rate: 500 * 1,
      secs: 5,
    },
    NOM_ECG_ELEC_POTL_II: {
      name: 'II',
      model: 'NOM_ECG_ELEC_POTL_II',
      color: '#62fe34',
      rate: 500 * 1,
      secs: 5,
    },
    NOM_ECG_ELEC_POTL_III: {
      name: 'III',
      model: 'NOM_ECG_ELEC_POTL_III',
      color: '#62fe34',
      rate: 500 * 1,
      secs: 5,
    },
    MDC_ECG_ELEC_POTL_I: {
      name: 'I',
      model: 'MDC_ECG_ELEC_POTL_I',
      color: '#62fe34',
      rate: 500 * 1,
      secs: 5,
    },
    MDC_ECG_ELEC_POTL_II: {
      name: 'II',
      model: 'MDC_ECG_ELEC_POTL_II',
      color: '#62fe34',
      rate: 500 * 1,
      secs: 5,
    },
    MDC_ECG_ELEC_POTL_III: {
      name: 'III',
      model: 'MDC_ECG_ELEC_POTL_III',
      color: '#62fe34',
      rate: 500 * 1,
      secs: 5,
    },
    NOM_PLETH: {
      name: 'PLETH',
      model: 'NOM_PLETH',
      color: '#00deff',
      rate: 127.8 * 1,
      secs: 5,
    },
    NOM_RESP: {
      name: 'RESP',
      model: 'NOM_RESP',
      color: '#FFCC00',
      rate: 127.8 * 2,
      secs: 5,
    },
    NOM_ECG_ELEC_POTL_AVR: {
      name: 'AVR',
      model: 'NOM_ECG_ELEC_POTL_AVR',
      color: '#62fe34',
      rate: 500,
      secs: 5,
    },
    NOM_ECG_ELEC_POTL_AVL: {
      name: 'AVL',
      model: 'NOM_ECG_ELEC_POTL_AVL',
      color: '#62fe34',
      rate: 500,
      secs: 5,
    },
  },
};
