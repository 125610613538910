<section class="historic-page skeleton">
  <div class="container">
    <div class="controller-container">
      <div class="btn-container s"></div>

      <div class="infos-curves">
        <span class="date s"></span>
        <span class="date s"></span>
      </div>
    </div>

    <div class="curves-param s"></div>
  </div>
</section>
