import { CommonModule, DatePipe } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';

import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { IconDirective } from 'src/app/directives/icon.directive';
import { InitialLettersDirective } from 'src/app/directives/initial-letters.directive';
import { InputFileDirective } from 'src/app/directives/input-file.directive';
import { InputNumberDirective } from 'src/app/directives/input-number.directive';
import { FormattedDatePipe } from 'src/app/pipes/formatted-date.pipe';
import { AccordionPanelLoadingComponent } from './accordion-panel-loading/accordion-panel-loading.component';
import { AvatarComponent } from './avatar/avatar.component';
import { CurvesCardComponent } from './curves-card/curves-card.component';
import { FooterComponent } from './footer/footer.component';
import { LoadingComponent } from './loading/loading.component';
import { PageLoadingComponent } from './page-loading/page-loading.component';
import { PaginationComponent } from './pagination/pagination.component';
import { TablePageLoadingComponent } from './table-page-loading/table-page-loading.component';
import { ZoomableDirective } from '../../directives/zoomable.directive';
import { HistoricLoadingComponent } from './historic-loading/historic-loading.component';
import { StopPropagDirective } from '../../directives/stop-propag.directive';
import { CurvesHistoryCardComponent } from './curves-history-card/curves-history-card.component';
import { FpsMeterComponent } from './fps-meter/fps-meter.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, RouterModule],
  declarations: [
    LoadingComponent,
    PageLoadingComponent,
    PaginationComponent,
    IconDirective,
    InputFileDirective,
    InputNumberDirective,
    TablePageLoadingComponent,
    CurvesCardComponent,
    CurvesHistoryCardComponent,
    FormattedDatePipe,
    InitialLettersDirective,
    AvatarComponent,
    FooterComponent,
    AccordionPanelLoadingComponent,
    ZoomableDirective,
    HistoricLoadingComponent,
    StopPropagDirective,
    FpsMeterComponent,
  ],
  exports: [
    LoadingComponent,
    PageLoadingComponent,
    PaginationComponent,
    HistoricLoadingComponent,
    IconDirective,
    InputFileDirective,
    InputNumberDirective,
    MatButtonModule,
    TablePageLoadingComponent,
    CurvesCardComponent,
    CurvesHistoryCardComponent,
    FormattedDatePipe,
    InitialLettersDirective,
    AvatarComponent,
    FooterComponent,
    AccordionPanelLoadingComponent,
    StopPropagDirective,
    FpsMeterComponent,
  ],
  providers: [
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: LOCALE_ID, useValue: 'pt' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class SharedModule {}
