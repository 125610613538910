export const VISUAL_IDENTIFY = [
  {
    title: 'AS Health Care',
    logo: 'assets/images/as-health-care/logo.png',
    navbar: 'assets/images/as-health-care/logo.png',
    icon: 'assets/images/as-health-care/favicon.ico'
  },
  {
    title: 'HemerOS',
    logo: 'assets/images/hemer-os/logo.png',
    navbar: 'assets/images/hemer-os/logo-2.png',
    icon: 'assets/images/hemer-os/favicon.ico'
  },
]

export const INDEX_VISUAL = 1
export const VISUAL_PROJECT = VISUAL_IDENTIFY[INDEX_VISUAL]
