import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import {
  AutoCursorModes,
  AxisTickStrategies,
  ColorHEX,
  PointShape,
  SolidFill,
  SolidLine,
  Themes,
  emptyFill,
  emptyLine,
  transparentFill,
} from '@lightningchart/lcjs';
import html2canvas from 'html2canvas';
import { EMPTY, debounceTime, fromEvent, interval, switchMap } from 'rxjs';
import { CONFIG_CURVES } from 'src/app/constants/curves';
import { IHistoryCurvesCard } from 'src/app/models/historic';
import { IRealtimeWave } from 'src/app/models/monitoring';
import { CurvesService, Message } from 'src/app/services/curves/curves.service';
import { LcContextService } from 'src/app/services/lc-context/lc-context.service';

@Component({
  selector: 'curves-card',
  templateUrl: './curves-card.component.html',
  styleUrls: ['./curves-card.component.scss'],
})
export class CurvesCardComponent implements AfterViewInit, OnDestroy {
  @Input() id = 0;
  @Input() waves = {} as IRealtimeWave;
  @Input() history = {} as IHistoryCurvesCard;
  @Input() small = false;
  @Input() order_waves = CONFIG_CURVES.waves;

  image = '';

  destroyLC?: () => unknown;

  constructor(
    private lcContextService: LcContextService,
    private curvesService: CurvesService
  ) {}

  private usingActiveWaves = Object.entries(this.order_waves).filter(
    (entry) => this.waves[entry[0] as keyof IRealtimeWave]
  );

  containerId = (Math.random() * 1_000_000_000).toFixed(0);
  resize$ = fromEvent(window, 'resize').pipe(debounceTime(1000));
  wavesListener$ = interval(1000).pipe(switchMap(() => {
    const activeWaves = Object.entries(this.order_waves).filter(
      (entry) => this.waves[entry[0] as keyof IRealtimeWave]
    );

    const newKeys = activeWaves.map(([key]) => key);
    const oldKeys = this.usingActiveWaves.map(([key]) => key);
    if (newKeys.length !== oldKeys.length) {
      this.refreshCurves();
      return EMPTY;
    }

    const hasDiff = newKeys.some((key) => !oldKeys.includes(key));
    if (hasDiff) this.refreshCurves();
    return EMPTY;
  }));

  ngAfterViewInit(): void {
    const activeWaves = Object.entries(this.order_waves).filter(
      (entry) => this.waves[entry[0] as keyof IRealtimeWave]
    );
    const lc = this.lcContextService.getLightningChartContext();
    const layout = document.getElementById(this.containerId) as HTMLDivElement;
    this.usingActiveWaves = [...activeWaves];
    const wavesListenerSubscription = this.wavesListener$.subscribe();
    const channels = activeWaves.map((entry, i) => {
      const [id, info] = entry;
      const timeViewMs = this.curvesService.timeViewMs(
        this.small ? layout : undefined
      );
      const timeViewSamplesApprox = Math.ceil((timeViewMs * info.rate) / 1000);
      const solidLine = new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: ColorHEX(info.color) }),
      });
      const container = document.createElement('div');
      container.style.flexGrow = '1';
      layout?.append(container);
      const chart = lc
        .ChartXY({
          container,
          animationsEnabled: false,
          interactable: false,
          theme: Themes.darkGold,
        })
        .setAutoCursorMode(AutoCursorModes.disabled)
        .setTitle(id)
        .setTitlePosition('series-left-top')
        .setPadding(0)
        .setBackgroundFillStyle(transparentFill)
        .setSeriesBackgroundFillStyle(transparentFill)
        .setSeriesBackgroundStrokeStyle(emptyLine);
      chart.engine
        .setBackgroundFillStyle(emptyFill)
        .setBackgroundStrokeStyle(emptyLine);
      chart.forEachAxis((axis) =>
        axis.setThickness(0).setStrokeStyle(emptyLine)
      );
      chart.axisX
        .setTickStrategy(AxisTickStrategies.Empty)
        .setInterval({ start: 0, end: timeViewSamplesApprox });
      chart.axisY.setTickStrategy(AxisTickStrategies.Empty);
      const series = chart
        .addPointLineAreaSeries({
          dataPattern: 'ProgressiveX',
          automaticColorIndex: i,
        })
        .setAreaFillStyle(emptyFill)
        .setStrokeStyle(solidLine)
        .setMaxSampleCount({ mode: 'auto' });
      const pointSeries = chart
        .addPointLineAreaSeries({
          dataPattern: null,
          automaticColorIndex: i,
        })
        .setAreaFillStyle(emptyFill)
        .setStrokeStyle(solidLine)
        .setPointShape(PointShape.Circle)
        .setPointSize(10)
        .setPointFillStyle(new SolidFill({ color: ColorHEX(info.color) }));
      return {
        id,
        info,
        chart,
        series,
        pointSeries,
        timeViewSamplesApprox,
        lastSampleIndex: -1,
        bufferModulus: 0,
      };
    });
    const pushDataToChart = (ch: (typeof channels)[0], chData: number[]) => {
      // Derived from https://lightningchart.com/js-charts/interactive-examples/edit/lcjs-example-0041-sweepingLineChartNew.html
      const newSamplesCount = chData.length;
      // Calculate which samples can be appended to right side, and which have to be started again from left side of sweeping history.
      const space = ch.timeViewSamplesApprox - (ch.lastSampleIndex + 1);
      // Put first set of samples to extend previous samples.
      const countRight = Math.min(space, newSamplesCount);
      ch.series.alterSamples(ch.lastSampleIndex + 1, {
        yValues: chData,
        count: countRight,
      });
      ch.lastSampleIndex += countRight;
      // Remove the few oldest points that would be connected to last points pushed just now, to leave a gap between newest and oldest data.
      const gapRightCount =
        countRight < space
          ? Math.min(
              Math.round(ch.timeViewSamplesApprox * 0.05),
              ch.timeViewSamplesApprox - (ch.lastSampleIndex + 1)
            )
          : 0;
      if (gapRightCount > 0) {
        ch.series.alterSamples(ch.lastSampleIndex + 1, {
          yValues: new Array(gapRightCount).fill(Number.NaN),
        });
      }
      // Put other samples (if any) to beginning of sweeping history.
      const countLeft = newSamplesCount - countRight;
      if (countLeft > 0) {
        ch.series.alterSamples(0, { yValues: chData, offset: countRight });
        ch.lastSampleIndex = countLeft - 1;
      }
      // Same gap but for "left" data
      const gapLeftCount = Math.min(
        Math.round(ch.timeViewSamplesApprox * 0.05),
        ch.timeViewSamplesApprox - (ch.lastSampleIndex + 1)
      );
      if (gapLeftCount > 0) {
        ch.series.alterSamples(ch.lastSampleIndex + 1, {
          yValues: new Array(gapLeftCount).fill(Number.NaN),
        });
      }
      ch.pointSeries.setSamples({
        xValues: [ch.lastSampleIndex],
        yValues: [chData[chData.length - 1]],
      });
    };
    //
    // Implement custom buffering logic for desired behavior:
    // - data arrives roughly every ~5 seconds
    // - data is buffered and pushed to charts in small bits
    // - application tries to keep a static buffer in memory with main goal of constantly pushing data to view
    const dataBuffer = new Map<
      string,
      { timestamp: number; measurement: number }[]
    >();

    const dataObservable = this.history.equipment
      ? this.curvesService.getHistory(this.history)
      : this.curvesService.connect(this.id);

    let tLastData: number | undefined;
    const dataSubscription = dataObservable.subscribe({
      next: (data: Message) => {
        tLastData =
          tLastData ??
          Date.now() - CONFIG_CURVES.approxDataIntervalFromServerMs;
        const tPrevious = tLastData;
        const tNow = Date.now();
        channels.forEach((ch) => {
          const chData = data.waves?.[ch.id];
          if (!chData) return;
          let buffer = dataBuffer.get(ch.id);
          if (!buffer) {
            buffer = [];
            dataBuffer.set(ch.id, buffer);
          }
          // Save incoming data points with a approximated timestamps to be used with buffering logic
          const chDataTimestamped = chData.map((measurement, i, arr) => ({
            timestamp: tPrevious + ((i + 1) / arr.length) * (tNow - tPrevious),
            measurement,
          }));
          buffer.push(...chDataTimestamped);
        });
        tLastData = tNow;
      },
    });
    //
    let tPrev = Date.now();
    const updateBufferToChart = () => {
      const tNow = Date.now();
      const tDelta = tNow - tPrev;
      tPrev = tNow;
      const tDisplay =
        tNow - 1.5 * CONFIG_CURVES.approxDataIntervalFromServerMs;
      channels.forEach((ch) => {
        const buffer = dataBuffer.get(ch.id);
        if (!buffer) return;
        const chData: number[] = [];
        if (buffer.length > 0) {
          while (buffer.length > 0) {
            if (buffer[0].timestamp <= tDisplay) {
              chData.push(buffer.shift()?.measurement || 0);
            } else {
              break;
            }
          }
        }
        if (chData.length === 0) {
          // works fine as long as ch rate is at least 100 Hz or so ... if smaller, then does NOT work correct
          const pushPointsPerSecond = ch.info.rate;
          let pushGapCount =
            pushPointsPerSecond * (tDelta / 1000) + ch.bufferModulus;
          ch.bufferModulus = pushGapCount % 1;
          pushGapCount = Math.floor(pushGapCount);
          for (let i = chData.length; i < pushGapCount; i += 1) {
            chData.push(Number.NaN);
          }
        }
        pushDataToChart(ch, chData);
      });
      requestAnimationFrame(updateBufferToChart);
    };
    updateBufferToChart();

    const resizeSubscription = this.resize$.subscribe(() => {
      console.log('AAAAAAAAAA');
      this.refreshCurves();
    });

    // Cache function that destroys created LC resources
    this.destroyLC = () => {
      channels.forEach((ch) => ch.chart.dispose());
      dataSubscription.unsubscribe();
      layout.innerHTML = '';
      this.curvesService.disconnect(this.id);
      resizeSubscription.unsubscribe();
      wavesListenerSubscription.unsubscribe();
    };
  }

  ngOnDestroy(): void {
    console.log('E MORREU');

    if (this.destroyLC) this.destroyLC();
  }

  private refreshCurves() {
    if (this.destroyLC) this.destroyLC();
    this.ngAfterViewInit();
  }

  async printscreenChart(e: Event) {
    e.stopPropagation();
    const layout = document.getElementById(this.containerId) as HTMLDivElement;

    if (!layout) return;

    const width = layout.offsetWidth;
    const height = layout.offsetHeight;
    const canvas = document.createElement('canvas');
    const scale = 2;

    canvas.width = width * scale;
    canvas.height = height * scale;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
    const context = canvas.getContext('2d');
    if (!context) return;

    context.scale(scale, scale);
    context.fillRect(0, 0, width, height);

    const elCanvas = await html2canvas(layout);
    context.drawImage(elCanvas, 0, 0, width, height, 0, 0, width, height);

    const img = canvas.toDataURL('image/png');
    this.image = img;
  }

  restartTimer() {
    fromEvent(document, 'mousemove')
      .pipe(
        debounceTime(10000),
        switchMap(() => {
          this.closeImage();
          return EMPTY;
        })
      )
      .subscribe();
  }

  closeImage(e?: Event) {
    e?.stopPropagation();
    this.image = '';
  }
}
