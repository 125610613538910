import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fps-meter',
  templateUrl: './fps-meter.component.html',
  styleUrls: ['./fps-meter.component.scss'],
})
export class FpsMeterComponent implements OnInit {
  fps = 0;

  ngOnInit(): void {
    this.startFpsMeter();
  }

  startFpsMeter(): void {
    let start = performance.now();
    let frameCount = 0;

    const callback = () => {
      frameCount++;
      const now = performance.now();
      if (now - start >= 1000) {
        this.fps = frameCount;
        frameCount = 0;
        start = now;
      }
      requestAnimationFrame(callback);
    };

    requestAnimationFrame(callback);
  }
}
