import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private notifier: NotifierService) {}

  error(message: string) {
    this.notifier.show({
      type: 'error',
      message,

    });
  }

  info(message: string) {
    this.notifier.show({
      type: 'info',
      message,
    });
  }

  success(message: string) {
    this.notifier.show({
      type: 'success',
      message,
    });
  }

  warning(message: string) {
    this.notifier.show({
      type: 'warning',
      message,
    });
  }

  messageAlarm(message: string, type?: string, timeout?: number) {
    switch (type) {
      case 'LOW':
        this.notifier.notify('info', message);
        break;
      case 'MEDIUM':
        this.notifier.notify('warning', message);
        break;
      case 'HIGH':
        this.notifier.notify('error', message);
        break;
    }
  }
}
