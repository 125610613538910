<div class="curves-card-container">
  <div [id]="this.containerId" class="curves-card" [ngClass]="{small}"></div>
  <button (click)="printscreenChart($event)" *ngIf="!image" mat-icon-button>
    <i icon="screenshot"></i>
  </button>
  <div class="image" *ngIf="image">
    <img zoomable [src]="image" alt="Curva de Ajuste" />
    <button (click)="closeImage($event)" mat-icon-button>
      <i icon="close"></i>
    </button>
  </div>
</div>
<!-- *ngIf="channel.data" -->
