import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, interval, map, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LevelAccessService } from '../../services/global/level-access.service';
import { StorageService } from './../../services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { AlarmsService } from 'src/app/services/alarms/alarms.service';
import { IActiveAlarm } from 'src/app/models/active-alarm';
import { SnackbarService } from 'src/app/services/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';
import { VISUAL_PROJECT } from 'src/app/constants/visual-identity';
import { SystemSettingsService } from 'src/app/services/system-settings/system-settings.service';
import { MatSidenav } from '@angular/material/sidenav';

declare global {
  interface HTMLElement {
    mozRequestFullScreen?(): void;

    webkitRequestFullScreen?(): void;

    msRequestFullscreen?(): void;
  }

  interface Document {
    mozCancelFullScreen?(): void;

    webkitExitFullscreen?(): void;

    msExitFullscreen?(): void;
  }
}


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @ViewChild('drawer') drawer: MatSidenav = {} as MatSidenav;
  @ViewChild('navbar') navbar: ElementRef<HTMLElement> | undefined;

  constructor(
    private storage: StorageService,
    private authService: AuthService,
    private router: Router,
    private systemSettingsService: SystemSettingsService,
    private levelAccessService: LevelAccessService,
    public translate: TranslateService,
    private alarmsService: AlarmsService,
    private snackbar: SnackbarService
  ) {
    const language = localStorage.getItem('language');
    let browserLang: any = translate.getBrowserLang();
    browserLang = browserLang === 'pt' ? 'pt-br' : browserLang;
    browserLang = browserLang.match(/pt-br|en/) ? browserLang : 'pt-br';
    translate.use(language || browserLang);
    // translate.use('pt-br');
  }

  @HostListener('document:fullscreenchange', ['$event'])
  onFullScreenChange(event: Event): void {
    this.isFullScreen = !!document.fullscreenElement;
  }

  visual_project = VISUAL_PROJECT;

  loading = true;
  hidden = false;
  config = this.storage.mysetting;
  user = this.storage.myself;
  isFullScreen = false;
  fontSize = 1;

  socketAlarm = new WebSocket(environment.socket.monitor_param);

  interval: Subscription = new Subscription();

  alarms: IActiveAlarm[] = [];
  load_alarm = false;

  now = interval(1000).pipe(
    map(() => {
      return new Date();
    })
  );

  ngOnInit(): void {
    if (this.interval) {
      this.interval.unsubscribe();
    }

    this.getMe();
    this.getSettings();
    this.storage.watchUser().subscribe({
      next: () => {
        this.getMe();
      },
    });

    this.storage.watchNavbar().subscribe({
      next: async (value?: boolean) => {
        if (typeof value === 'boolean') {
          this.hidden = value;
          return;
        }
        this.hidden = !this.hidden;
        await this.toggleFullScreen();
      },
    });

    this.router.events.subscribe({
      next: () => {
        if (!this.router.url.includes('/monitoring')) {
          this.storage.changeNavbar(false);
        }
      },
    });

    this.getActiveAlarm();

    this.interval = interval(5000).subscribe({
      next: () => {
        this.getActiveAlarm();
      },
    });
  }

  drawerToggle() {
    this.drawer.toggle();
    setTimeout(() => {
      dispatchEvent(new Event('resize'));
    }, 300);
  }

  getActiveAlarm() {
    if (this.load_alarm) return;

    this.load_alarm = true;
    this.alarmsService.getActiveAlarm().subscribe({
      next: (data) => {
        // Filter alarms based on time and other conditions
        const filteredAlarms = data.filter((alarm) =>
          this.filterElement(alarm, 1)
        ); // Filtrar alarmes atualizados nas últimas 1 hora

        // Efficiently identify new items and existing ones
        const newAlarms = filteredAlarms.filter(
          (activeAlarm) =>
            !this.alarms.some(
              (existingAlarm) => existingAlarm.id === activeAlarm.id
            )
        );

        // Update the alarms array for consistency
        this.alarms = data.slice(); // Create a copy to avoid mutation


        this.alarmsService.changeAlarm(data);

        this.load_alarm = false;
        // Show Snackbars only for new alarms
        newAlarms.forEach((newAlarm) => {
          this.openMessage(newAlarm);
        });
      },
    });
  }

  async openMessage(newAlarm: {
    max: number;
    value: number;
    min: number;
    params: string;
    priority: string;
    bed: {
      id: number;
      name: string;
    };
    department: string;
  }) {
    if (newAlarm?.max && newAlarm.value > newAlarm.max) {
      const text = await this.getTranslatedValue('pipes.priorityAlarms.HIGHT');
      this.snackbar.messageAlarm(
        `🚨 ${newAlarm.params} ${text}, ${text} , ${newAlarm.bed.name} | ${newAlarm.department}`,
        newAlarm.priority
      );
    } else if (newAlarm?.min && newAlarm.value < newAlarm.min) {
      const text = await this.getTranslatedValue('pipes.priorityAlarms.LOW');
      this.snackbar.messageAlarm(
        `🚨 ${newAlarm.params} ${text} , ${newAlarm.bed.name} | ${newAlarm.department}`,
        newAlarm.priority
      );
    }
  }

  filterElement(el: any, time: number): boolean {
    const oneHourAgo = new Date(Date.now() - time * 1000 * 60 * 60); // One hour in milliseconds
    const updatedDate = new Date(el.updated_at);

    return (
      el.is_active &&
      el.is_alarming &&
      (el.value > el.max || el.value < el.min) &&
      updatedDate >= oneHourAgo
    );
  }

  async toggleFullScreen() {
    if (!this.isFullScreen) {
      const docElm: HTMLElement = document.documentElement;
      if (docElm.requestFullscreen) {
        await docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      const doc: Document = document;
      if (doc.exitFullscreen) {
        await doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) {
        doc.msExitFullscreen();
      }
    }
  }

  requestFullScreen(element: any) {
    // Supports most browsers and their versions.
    const requestMethod =
      element.requestFullScreen ||
      element.webkitRequestFullScreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullScreen;

    if (requestMethod) {
      // Native full screen.
      requestMethod.call(element);
    }
    // else if (typeof window.ActiveXObject !== "undefined") {
    //   // Older IE.
    //   const wscript = new ActiveXObject("WScript.Shell");
    //   if (wscript !== null) {
    //     wscript.SendKeys("{F11}");
    //   }
    // }
  }

  logout() {
    this.storage.logout();
  }

  getMe() {
    this.authService.getMe().subscribe({
      next: (user) => {
        this.storage.myself = user;
        this.user = user;
      },
      error: (error) => {
        if (error?.status === 401) {
          this.storage.logout();
        }
      },
    });
  }

  getSettings() {
    this.systemSettingsService.getSettings().subscribe({
      next: (data) => {
        this.config = data;
        this.storage.mysetting = data;
        this.loadFontSize();
        this.loading = false;
      },
    });
  }

  loadFontSize() {
    this.fontSize = this.systemSettingsService.fontSize;
    this.changeFontSize();
  }

  changeFontSize(type?: 'decrease' | 'increase' | 'reset') {
    if (type === 'decrease') {
      this.fontSize--;
    }
    if (type === 'increase') {
      this.fontSize++;
    }
    if (type === 'reset') {
      this.fontSize = 1;
    }

    const newFontSize = `calc(${16 + this.fontSize}px + 0.3125vw)`;
    this.systemSettingsService.fontSize = this.fontSize;
    document.documentElement.style.setProperty('font-size', newFontSize);
  }

  checkAccess(path: string) {
    return this.levelAccessService.checkLevelAccess(path).includes('read');
  }

  async getTranslatedValue(value: string) {
    return firstValueFrom(this.translate.get(value));
  }
}
