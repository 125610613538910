import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IActiveAlarm } from 'src/app/models/active-alarm';
import {
  IAlarmResults,
  ICreateAlarm,
  IParamsByEquipement,
  IUpdateAlarm,
} from 'src/app/models/alarms';
import { TAlarmPriority } from 'src/app/models/beds';
import { IPagedReq, IReqFilter } from 'src/app/models/utils';
import { environment } from 'src/environments/environment';
import { BodyJson, HttpService } from '../http/http.service';
import { StorageService } from '../storage/storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlarmsService {
  constructor(private http: HttpService, private storage: StorageService) {}

  AlarmSubject = new Subject<void>();
  alarm_obj: IActiveAlarm[] = []

  get alarm(): IActiveAlarm[] {
    return this.alarm_obj;
  }

  set alarm(alarm: IActiveAlarm[]) {
    this.alarm_obj = alarm;
  }

  watchAlarm() {
    return this.AlarmSubject.asObservable();
  }

  changeAlarm(alarm: IActiveAlarm[]): void {
    this.alarm_obj = alarm;
    this.AlarmSubject.next();
  }

  getAlarms(page: number, filters: IReqFilter) {
    const params = new HttpParams({ fromObject: filters })
      .set('page', page)
      .set('page_size', environment.page_size);

    return this.http.get<IPagedReq<IAlarmResults>>('core/list-alarm/', params);
  }

  getActiveAlarm(filter = 'priority') {
    const params = new HttpParams()
      .set('order_by', filter)
    return this.http.get<IActiveAlarm[]>('core/active-alarm/', params);
  }

  patchAlarm(alarm: IUpdateAlarm) {
    const body = {
      id: alarm.id || 0,
      priority: alarm.priority,
      name: alarm.name,
      min: Number(alarm.min),
      max: Number(alarm.max),
      equipment: alarm.equipment,
      param: Number(alarm.param),
    };

    return this.http.patch<IUpdateAlarm[]>(
      `core/update-alarm/?id=${alarm.id}`,
      body
    );
  }

  getParamsByEquipment(equipmentId: number) {
    return this.http.get<IParamsByEquipement[]>(
      `core/list-param-by-equipment?equipment=${equipmentId}`
    );
  }

  getParamsByEquipmentModal(idEquipment: number) {
    return this.http.get<IParamsByEquipement[]>(
      `core/list-param-by-equipment?equipment=${idEquipment}`
    );
  }

  postAlarm(alarm: ICreateAlarm) {
    const body = {
      equipment: alarm.equipment,
      param: alarm.param,
      name: alarm.name,
      min: alarm.min,
      max: alarm.max,
      priority: alarm.priority,
    };
    return this.http.post<ICreateAlarm>('core/create-alarm/', body);
  }

  updateAlarming(body: BodyJson, id: number) {
    const params = new HttpParams().set('id', id);
    return this.http.post<ICreateAlarm>('core/update-alarming/', body, params);
  }

  handleAlarmClass(alarm: TAlarmPriority) {
    switch (alarm) {
      case 'HIGH':
        return 'red';
      case 'MEDIUM':
        return 'yellow';
      case 'LOW':
        return 'blue';
      default:
        return '';
    }
  }

  deletParam(id: number) {
    const params = new HttpParams().set('id', id);
    return this.http.delete('core/delete-param-by-id/', params);
  }

  deletAlarm(id: number) {
    const params = new HttpParams().set('id', id);
    return this.http.delete('core/delete-alarm/', params);
  }
}
